import React from "react";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { WhatsAppItem } from "../WhatsAppItem";
import { FacebookItem } from "../FacebookItem";
import { ConnectionModal } from "../new";
import { i18n } from "../../../../translate/i18n";
import toastError from "../../../../errors/toastError";
import api from "../../../../services/api";
import QrcodeModal from "../../../../components/QrcodeModal";

export function ItemList({ connection }) {
  const [connectionId, setConnectionId] = React.useState("");
  const [confirmModalInfo, setConfirmModalInfo] = React.useState(null);
  const [openCode, setOpenCode] = React.useState(false);

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "clear") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.clearTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
      });
    }
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`, {
          params: {
            force: confirmModalInfo.force,
          },
        });
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        const errorMsg =
          err.response?.data?.message || err.response?.data?.error;
        if (errorMsg === "ERR_CONTAINS_TICKET") {
          setConfirmModalInfo(null);
          setConfirmModalInfo({
            title: i18n.t(`backendErrors.${errorMsg}`),
            message: i18n.t("connections.confirmationModal.forceDelete"),
            whatsAppId: confirmModalInfo.whatsAppId,
            force: true,
            action: "delete",
          });
          return;
        }
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "clear") {
      try {
        await api.delete(
          `/whatsappsession/${confirmModalInfo.whatsAppId}/clear`,
        );
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(null);
  };

  function RenderConnection() {
    if (connection.id.includes("META")) {
      return (
        <FacebookItem
          whatsApp={connection}
          handleEdit={(value) => setConnectionId(value)}
          handleConfirm={(type, value) =>
            handleOpenConfirmationModal(type, value)
          }
        />
      );
    }
    return (
      <WhatsAppItem
        whatsApp={connection}
        handleEdit={(value) => setConnectionId(value)}
        handleConfirm={(type, value) =>
          handleOpenConfirmationModal(type, value)
        }
        handleCode={() => setOpenCode(true)}
      />
    );
  }

  return (
    <>
      <ConnectionModal
        open={!!connectionId}
        onClose={() => setConnectionId("")}
        whatsAppId={connectionId}
      />
      {openCode && (
        <QrcodeModal
          open={true}
          onClose={() => setOpenCode(false)}
          whatsAppId={connection.id}
        />
      )}
      {confirmModalInfo && (
        <ConfirmationModal
          title={confirmModalInfo.title}
          open={confirmModalInfo}
          onClose={() => setConfirmModalInfo(null)}
          onConfirm={handleSubmitConfirmationModal}
        >
          {confirmModalInfo.message}
        </ConfirmationModal>
      )}
      <RenderConnection />
    </>
  );
}
