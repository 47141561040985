import React from "react";
import {
  Dialog,
  makeStyles,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  FormHelperText,
  Box,
  Typography,
} from "@material-ui/core";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const rotationSchema = Yup.object({
  groups: Yup.array(
    Yup.object({
      queueId: Yup.string().uuid().required(),
      maxTickets: Yup.number().min(0).integer(),
    })
  ),
});
export function RotationModal({ open, handleClose, rotation = null }) {
  const [isLoading, setLoading] = React.useState(false);
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
  } = useForm({
    resolver: yupResolver(rotationSchema),
    defaultValues: {
      groups: [],
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: "groups",
    rules: {
      required: "Please append at least 1 item",
    },
  });

  React.useEffect(() => {
    function Replace() {
      setLoading(true);
      if (rotation) {
        replace(rotation.groups.map((group) => group));
      }
      setLoading(false);
    }

    Replace();
  }, [rotation, replace]);

  const onSubmit = async (data) => {
    const duplicateIndex = data.groups.findIndex(
      (elem, index, arr) =>
        arr.findIndex((e) => e.queueId === elem.queueId) !== index
    );
    if (duplicateIndex !== -1) {
      setError(`groups[${duplicateIndex}].queueId`, {
        message: i18n.t("rotations.modal.duplication"),
      });
      return;
    }

    const body = data.groups.map(({ id, maxTickets }) => ({
      id,
      maxTickets,
    }));

    try {
      await api.put("whatsapp/groups/update/" + rotation.id, body);
      handleClose();
    } catch (err) {
      handleClose();
      toastError(err);
    }
  };

  if (!rotation) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {rotation
            ? `${i18n.t("rotations.modal.title.edit")} ${rotation.name}`
            : `${i18n.t("rotations.modal.title.add")}`}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {fields.map((field, index) => (
                <>
                  <Typography>{field.queue.name}</Typography>
                  <div
                    key={`${field.id}-${index}-group`}
                    className={classes.multFieldLine}
                  >
                    <FormControl
                      fullWidth
                      error={
                        errors.groups && !!errors.groups[index]?.maxTickets
                      }
                    >
                      <TextField
                        id="maxTickets"
                        type={"number"}
                        inputProps={{
                          min: 0,
                        }}
                        name="maxTickets"
                        label={`${i18n.t(
                          "rotations.modal.groups.maxTicket"
                        )} (0 = ${i18n.t(
                          "rotations.modal.groups.maxTicket_warn"
                        )})`}
                        defaultValue={0}
                        {...register(`groups.${index}.maxTickets`, {
                          valueAsNumber: true,
                          min: 0,
                        })}
                      />
                      <FormHelperText>
                        {errors.groups &&
                          errors.groups[index]?.maxTickets?.message.replace(
                            `groups[${index}].maxTickets`,
                            ""
                          )}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </>
              ))}

              <Box
                display="flex"
                component={"div"}
                justifyContent={"flex-end"}
                my={1}
                gridGap={4}
              >
                <Button
                  type="submit"
                  size={"large"}
                  color="primary"
                  variant="contained"
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  ) : (
                    `${
                      rotation
                        ? i18n.t("rotations.modal.edit")
                        : i18n.t("rotations.modal.create")
                    }`
                  )}
                </Button>
              </Box>
              <p>{errors.root?.message}</p>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
