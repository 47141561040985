import React from "react";
import { useHistory } from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import {
  Button,
  Paper,
  makeStyles,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import api from "../../services/api";
import { useAuthUser } from "../../context/Auth";
import axios from "axios";
import toastError from "../../errors/toastError";
import { RotationModal } from "./new";
import { socket } from "../../services/socket-io";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Row } from "./components/row";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const reducer = (state, event) => {
  const { rotations, queues } = state;
  const { type, payload } = event;
  if (type === "FETCH_ROTATIONS") {
    const rotations = [];

    payload.forEach((elem) => {
      const index = rotations.findIndex(
        (rotate) => elem.whatsappId === rotate.id
      );
      if (index === -1) {
        rotations.push(elem);
        return;
      }
      rotations[index].groups.push(elem);
    });
    state.rotations = rotations;
  }

  if (type === "DELETE_ROTATION") {
    const index = rotations.findIndex((elem) => elem.id === payload);
    const queueIds = rotations[index].groups.map((elem) => elem.queueId);
    queues.available.push(
      ...queues.all.filter((elem) => queueIds.includes(elem.id))
    );
    rotations.splice(index, 1);
  }

  if (type === "UPDATE_ROTATION") {
    payload.forEach((group) => {
      const indexRotation = rotations.findIndex(
        (elem) => elem.id === group.whatsappOnGroupId
      );

      const indexGroup = rotations[indexRotation].groups.findIndex(
        (elem) => group.id === elem.id
      );

      queues.available.splice(
        queues.available.findIndex((e) => e === payload.queueId),
        1
      );
      const notUsed = queues.all.filter((elem) => elem.id !== payload.queueId);

      queues.available.push(
        ...notUsed.filter(
          (elem) => !queues.available.find((e) => e.id === elem.id)
        )
      );

      rotations[indexRotation].groups[indexGroup] = group;
    });
  }

  if (type === "FETCH_QUEUES") {
    queues.all = payload.all;
    queues.available = payload.available;
  }

  return state;
};

export function RotationsPage() {
  const classes = useStyles();
  const { user } = useAuthUser();
  const history = useHistory();

  const [state, setState] = React.useReducer(reducer, {
    queues: {
      available: [],
      all: [],
    },
    rotations: [],
  });

  const [selectRotation, setSelectRotation] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const handleDeleteRotation = async () => {
    try {
      if (!selectRotation) return;
      setIsLoading(true);

      await api.delete(`whatsapp/groups/delete/${selectRotation.id}`);
      setSelectRotation(null);
      setIsLoading(false);
    } catch (err) {
      setSelectRotation(null);
      setIsLoading(false);
      toastError(err);
    }
  };

  React.useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();

    async function fetchRotations() {
      try {
        if (unmounted) return;
        const groups = await api.get("whatsapp/groups/", {
          cancelToken: source.token,
        });

        const queues = await api.get("queue", {
          cancelToken: source.token,
        });

        setState({
          type: "FETCH_ROTATIONS",
          payload: groups.data,
        });

        const queuesAvailable = queues.data.filter(
          (queue) => !groups.data.find((group) => group.queueId === queue.id)
        );
        setState({
          type: "FETCH_QUEUES",
          payload: {
            all: queues.data,
            available: queuesAvailable,
          },
        });
        return;
      } catch (err) {
        toastError(err);
        return;
      }
    }

    function handleEvent(event) {
      setIsLoading(true);
      if (event.action === "delete") {
        setState({ type: "DELETE_ROTATION", payload: event.data });
      }

      if (event.action === "update") {
        setState({ type: "UPDATE_ROTATION", payload: event.data });
      }

      setIsLoading(false);
    }

    socket.on(`rotations:${user.companyId}`, handleEvent);

    fetchRotations().finally(() => setIsLoading(false));

    return () => {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
      socket.off(`rotations:${user.companyId}`, handleEvent);
    };
  }, [user]);

  return (
    <MainContainer>
      <ConfirmationModal
        title={selectRotation && `${i18n.t("rotations.modal.title.delete")}`}
        open={isDelete}
        onClose={() => setDelete(false)}
        onConfirm={handleDeleteRotation}
      >
        {i18n.t("rotations.modal.message.delete")}
      </ConfirmationModal>
      <RotationModal
        open={isOpen}
        handleClose={() => {
          if (selectRotation) {
            setSelectRotation(null);
          }
          setIsOpen(false);
        }}
        rotation={selectRotation}
      />
      <MainHeader>
        <Title>{i18n.t("rotations.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            disabled={state.queues.available.length === 0}
            onClick={() => {
              history.push("/connections");
            }}
          >
            {state.queues.available.length === 0
              ? i18n.t("rotations.buttons.disabled")
              : i18n.t("rotations.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper className={classes.mainPaper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {i18n.t("rotations.table.number")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("rotations.table.wpp_name")}
                </TableCell>

                <TableCell align="center">
                  {i18n.t("rotations.table.groups")}
                </TableCell>

                <TableCell align="center">
                  {i18n.t("rotations.table.limits")}
                </TableCell>

                <TableCell align="center">
                  {i18n.t("rotations.table.today")}
                </TableCell>

                <TableCell align="center">
                  {i18n.t("rotations.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.rotations.map((rotation, index) => (
                <Row
                  key={rotation.id}
                  rotation={rotation}
                  index={index}
                  setIsOpen={(data) => setIsOpen(data)}
                  setSelectRotation={(data) => setSelectRotation(data)}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </MainContainer>
  );
}
