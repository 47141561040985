import React from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Collapse,
  Typography,
  Box,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import { Edit, KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { i18n } from "../../../translate/i18n";
import api from "../../../services/api";

export function Row({ rotation, index, setSelectRotation, setIsOpen }) {
  const [collapse, setCollapse] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchDetail = async () => {
      if (!collapse) return [];
      setLoading(true);
      const groupIds = rotation.groups.map((elem) => elem.id);
      const { data } = await api.get(`/report/ids?ids=${groupIds.join(";")}`);
      setLoading(false);
      return data;
    };
    fetchDetail().then((elem) => setData(elem));
  }, [collapse, rotation.groups]);
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setCollapse((value) => !value)}
          >
            {collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell align="center">#{index + 1}</TableCell>

        <TableCell align="center">{rotation.whatsapp.name}</TableCell>
        <TableCell align="center">
          {rotation.groups.map((group) => (
            <p key={`${group.id}-name`}>
              {group.queue.name} - {group.queue._count.users}{" "}
              {i18n.t("rotations.table.users")}
            </p>
          ))}
        </TableCell>

        <TableCell align="center">
          {rotation.groups.map((group) => (
            <p key={`${group.id}-maxTickets`}>{group.maxTickets}</p>
          ))}
        </TableCell>

        <TableCell align="center">
          {rotation.groups.map((group) => (
            <p key={`${group.id}-today`}>{group._count.tickets}</p>
          ))}
        </TableCell>

        <TableCell align="center">
          <IconButton
            size="small"
            onClick={() => {
              setSelectRotation(rotation);
              setIsOpen(true);
            }}
          >
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Members
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>{i18n.t("rotations.table.name")}</TableCell>
                      <TableCell>{i18n.t("rotations.table.queue")}</TableCell>
                      <TableCell align="right">
                        {i18n.t("rotations.table.today")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((elem) => (
                      <TableRow key={elem.userId}>
                        <TableCell>{elem.name}</TableCell>
                        <TableCell>
                          {
                            rotation.groups.find(
                              (group) => group.queueId === elem.queueId
                            ).queue.name
                          }
                        </TableCell>
                        <TableCell align="right">
                          {elem.tickets_today}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
