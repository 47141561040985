import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Box, Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { useLocalStorage } from "../../util";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },

  settingOption: {
    marginLeft: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);

  const settings = [];

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const exportCSV = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await api.get("/settings/csv");
      const blob = new Blob([data], {
        type: "text/csv",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `contacts.csv`;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);

      URL.revokeObjectURL(url);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const getSettingValue = (key) => {
    const { value } = settings.find((s) => s.key === key);
    return value;
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="sm">
        <Typography variant="body2" gutterBottom>
          {i18n.t("settings.title")}
        </Typography>
        <Paper className={classes.paper}>
          <Typography variant="body1">
            {i18n.t("settings.settings.userCreation.name")}
          </Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="userCreation-setting"
            name="userCreation"
            value={
              settings && settings.length > 0 && getSettingValue("userCreation")
            }
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">
              {i18n.t("settings.settings.userCreation.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.userCreation.options.disabled")}
            </option>
          </Select>
        </Paper>

        <Paper className={classes.paper}>
          <TextField
            id="api-token-setting"
            readonly
            label="Token Api"
            margin="dense"
            variant="outlined"
            fullWidth
            value={
              settings && settings.length > 0 && getSettingValue("userApiToken")
            }
          />
        </Paper>

        <Paper className={classes.paper}>
          <TextField
            id="Version"
            label="version"
            margin="dense"
            variant="outlined"
            readonly
            disabled
            fullWidth
            value={useLocalStorage.getValue("version", "")}
          />
        </Paper>
        <Paper className={classes.paper}>
          <Box
            display={"flex"}
            flex={1}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography readonly disabled fullWidth>
              Exporta Contatos - CSV {isLoading && "(Gerando..)"}
            </Typography>
            <Button
              onClick={exportCSV}
              variant={"contained"}
              color={"primary"}
              size={"large"}
              disabled={isLoading}
            >
              <GetApp />
            </Button>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Settings;
