import React from "react";
import { Button, Box, CircularProgress } from "@material-ui/core";
import { PreviewFile } from "./preview";
import { useController } from "react-hook-form";
import api from "../../services/api";

export function InputFile({
  labelUpload = "Upload",
  labelRemove = "Remove",
  accept = "audio/*, image/*",
  multiple = false,
  register,
  name,
  control,
  mediaInitial,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [media, setMedia] = React.useState(mediaInitial);
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    control,
    name,
  });

  const handleRemove = async () => {
    setIsLoading(true);
    if (media && media.url) {
      await api.put("/media", {
        medias: [media],
      });
      setMedia({
        publicId: "",
        url: "",
        id: "",
        format: "",
      });
    }
    field.onChange();
    setIsLoading(false);
  };

  const isMedia = media && media.url;
  const isFile = field.value ? field.value.length > 0 : false;

  return (
    <Box flex={0.3} ml={3} gridGap={3} justifyContent={"center"}>
      {isLoading ? (
        <CircularProgress />
      ) : (
          <PreviewFile
            file={field.value}
            media={media}
            onRemove={handleRemove}
            labelRemove={labelRemove}
            isSubmitting={isSubmitting}
          />
        )}
      <input
        type={"file"}
        name={name}
        multiple={multiple}
        accept={accept}
        id="contained-button-file"
        style={{
          display: "none",
        }}
        {...register(name)}
      />
      {!isFile && !isMedia && (
        <label htmlFor={"contained-button-file"}>
          <Button
            disabled={isSubmitting}
            variant={"contained"}
            color={"default"}
            component={"span"}
          >
            {labelUpload}
          </Button>
        </label>
      )}
    </Box>
  );
}
