import React from "react";
import { Button } from "@material-ui/core";

export function ActionButton({
  label,
  variant = "outlined",
  onClick = () => {},
  disabled = false,
  color = "primary",
}) {
  return (
    <Button
      size="small"
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}
