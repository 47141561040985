import React from "react";
import { makeStyles, Box, IconButton, Button } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  messageMedia: {
    flex: 1,
    display: "flex",
    width: "100%",
    objectFit: "contain",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  button: {
    position: "absolute",
  },
  root: {
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
  },
}));

export function PreviewFile({
  file,
  onRemove,
  labelRemove,
  media,
  isSubmitting,
}) {
  const classes = useStyles();
  const fileSrc = React.useCallback((value) => {
    if (typeof value === "string") {
      return value;
    }
    return URL.createObjectURL(value[0]);
  }, []);

  const isMedia = media && media.url;
  const isFile = file && file.length > 0;

  if (!isFile && !isMedia) {
    return <></>;
  }

  let type = (isFile ? file[0].type : media.format || media.mimetype).split(
    "/",
  )[0];

  return (
    <div className={classes.root}>
      {type === "image" ? (
        <>
          <img
            className={classes.messageMedia}
            src={fileSrc(isFile ? file : media.url)}
            alt={"preview"}
          />
          <div className={classes.button}>
            <IconButton color={"secondary"} onClick={onRemove}>
              <DeleteOutline />
            </IconButton>
          </div>
        </>
      ) : (
          <Box flex={1}>
            <label>Audio: {isFile ? file[0].name : media.publicId}</label>
            <Button
              disabled={isSubmitting}
              color={"default"}
              variant={"contained"}
              onClick={onRemove}
            >
              {labelRemove}
            </Button>
          </Box>
        )}
    </div>
  );
}
