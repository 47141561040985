import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { i18n } from "../../translate/i18n";
import { ListItemText, Checkbox } from "@material-ui/core";
import { useController } from "react-hook-form";

export const MultiSelect = ({ index, control, options, renderValue, name }) => {
  const { field } = useController({
    control,
    name,
  });

  const getValue = ({ value }) => {
    if (value.length === 0) return value;

    if (typeof value[0] === "string") return value;

    return value.map((elem) => elem.optionId);
  };

  const getChecked = ({ value }, optionId) => {
    if (value.length === 0) return false;

    if (typeof value[0] === "string") return value.includes(optionId);

    return value.map((elem) => elem.optionId).includes(optionId);
  };

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <InputLabel>{i18n.t("multiSelect.inputLabel")}</InputLabel>
      <Select
        multiple
        value={getValue(field)}
        renderValue={renderValue}
        labelWidth={60}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        onChange={field.onChange}
      >
        {options.map((opt) => (
          <MenuItem key={opt.id} value={opt.id}>
            <Checkbox checked={getChecked(field, opt.id)} />
            <ListItemText primary={opt.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
