import {
  IconButton,
  TableCell,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../../styles";
import {
  CheckCircle,
  DeleteOutline,
  Edit,
  SignalCellularConnectedNoInternet0Bar,
  CropFree,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellular4Bar,
  WhatsApp,
  Cached,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../../../translate/i18n";
import CustomToolTip from "../../../../components/ToolTip";
import { format, parseISO } from "date-fns";
import { ActionButton } from "./actionButton";
import { useWhatsApps } from "../../../../context/WhatsApps";

export function WhatsAppItem({
  whatsApp,
  handleEdit,
  handleConfirm,
  handleCode,
}) {
  const classes = useStyles();
  const { startConnection, newQRCode } = useWhatsApps();

  const ActionsButtons = {
    QRCODE: {
      label: i18n.t("connections.buttons.qrcode"),
      onClick: handleCode,
      variant: "contained",
    },
    DISCONNECTED: [
      {
        label: i18n.t("connections.buttons.tryAgain"),
        onClick: () => startConnection(whatsApp.id),
      },
      {
        label: i18n.t("connections.buttons.newQr"),
        onClick: () => newQRCode(whatsApp.id),
        color: "secondary",
      },
    ],
    CONNECTED: {
      label: i18n.t("connections.buttons.disconnect"),
      onClick: () => {
        handleConfirm("disconnect", whatsApp.id);
      },
    },
    OPENING: {
      label: i18n.t("connections.buttons.connecting"),
      color: "default",
      disabled: true,
    },
    PENDING: {
      label: i18n.t("connections.buttons.pending"),
      color: "default",
      disabled: true,
    },
  };
  const renderActionButtons = (status) => {
    let action = ActionsButtons[status];
    if (!action) return <></>;
    if (!Array.isArray(action)) {
      action = [action];
    }
    return action.map((props, i) => (
      <ActionButton
        key={i}
        label={props.label}
        color={props.color}
        disabled={props.disabled}
        onClick={props.onClick}
        variant={props.variant}
      />
    ));
  };

  const renderStatusToolTips = () => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "PENDING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };
  return (
    <TableRow key={whatsApp.id}>
      <TableCell align="center">{whatsApp.name}</TableCell>
      <TableCell align="center">{renderStatusToolTips(whatsApp)}</TableCell>
      <TableCell align="center">
        {renderActionButtons(whatsApp.status)}
      </TableCell>
      <TableCell align="center">
        {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
      </TableCell>
      <TableCell align={"center"}>
        <WhatsApp />
      </TableCell>
      <TableCell align="center">
        {whatsApp.isDefault && (
          <div className={classes.customTableCell}>
            <CheckCircle style={{ color: green[500] }} />
          </div>
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          onClick={() => {
            handleConfirm("clear", whatsApp.id);
          }}
        >
          <Cached />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleEdit(whatsApp.id);
          }}
        >
          <Edit />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => {
            handleConfirm("delete", whatsApp.id);
          }}
        >
          <DeleteOutline />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
