import React from "react";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Colorize,
  HelpOutlineOutlined,
  InfoOutlined,
} from "@material-ui/icons";
import {
  IconButton,
  InputAdornment,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@material-ui/core";

import CustomToolTip from "../../../components/ToolTip";
import ColorPicker from "../../../components/ColorPicker";

import { i18n } from "../../../translate/i18n";

import api from "../../../services/api";
import toastError from "../../../errors/toastError";

import { toast } from "react-toastify";
import { Flow } from "./flow";
import { TabPanel } from "./tabs";
import ConfirmationModal from "../../../components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
    // alignSelf: "flex-end",
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  dialog: {
    height: "80vh",
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const [isConfirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const classes = useStyles();

  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    command: "",
    flow: null,
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = React.useState(false);
  const greetingRef = React.useRef();
  const [isLoading, setLoading] = React.useState(true);
  const [isLoadingFlow, setLoadingFlow] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(QueueSchema),
  });

  const handleDelete = async () => {
    const values = getValues();
    if (values.flow) {
      await api.delete("/flow/" + values.flow.id);
      setValue("flow", null);
      handleClose();
    }
  };

  const handleCreate = async () => {
    setLoadingFlow(true);
    if (!queueId) return;
    const { data } = await api.post("/flow", {
      queueId,
    });
    const { step, ...res } = data;
    setValue("flow", res);
    setLoadingFlow(false);
  };

  React.useEffect(() => {
    let unmounted = false;
    const controller = new AbortController();

    async function fetchQueue() {
      try {
        if (unmounted) return;
        if (!queueId || !open) return;
        const { data } = await api.get(`/queue/${queueId}`, {
          signal: controller.signal,
        });
        reset(data);
      } catch (err) {
        toastError(err);
      }
    }

    fetchQueue().finally(() => setLoading(false));

    return () => {
      unmounted = true;
      controller.abort();
      reset();
    };
  }, [queueId, open, reset]);

  const handleClose = () => {
    onClose();
    reset(initialState);
  };

  const handleSaveQueue = async (values) => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, values);
      } else {
        await api.post("/queue", values);
      }
      toast.success("Queue saved successfully");
      reset();
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <ConfirmationModal
        open={isConfirmDeleteModal}
        onClose={() => setConfirmDeleteModal(false)}
        title={"Você tem certeza?"}
        children={
          "Esta etapa possui subetapas. Se adicionar uma ação, todas as subetapas serão excluídas."
        }
        onConfirm={handleDelete}
      />
      <Dialog open={true} onClose={handleClose} scroll="paper" fullWidth>
        <DialogTitle>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {queueId
              ? `${i18n.t("queueModal.title.edit")}`
              : `${i18n.t("queueModal.title.add")}`}
            <Tabs value={tabIndex} onChange={(_, e) => setTabIndex(e)}>
              <Tab label={"Fila"} />
              <Tab label={"Fluxo"} disabled={!queueId} />
            </Tabs>
          </Box>
        </DialogTitle>
        <TabPanel value={tabIndex} index={0}>
          <form onSubmit={handleSubmit(handleSaveQueue)}>
            <Box minHeight={"60vh"} display={"flex"} flexDirection={"column"}>
              <DialogContent dividers>
                <TextField
                  label={i18n.t("queueModal.form.name")}
                  autoFocus
                  name="name"
                  error={errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                  {...register("name")}
                />
                <TextField
                  label={i18n.t("queueModal.form.color")}
                  name="color"
                  id="color"
                  onFocus={() => {
                    setColorPickerModalOpen(true);
                    greetingRef.current.focus();
                  }}
                  error={errors.color}
                  helperText={errors.color ? errors.color.message : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{ backgroundColor: watch("color") }}
                          className={classes.colorAdorment}
                        ></div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        size="small"
                        color="default"
                        onClick={() => setColorPickerModalOpen(true)}
                      >
                        <Colorize />
                      </IconButton>
                    ),
                  }}
                  variant="outlined"
                  margin="dense"
                />
                <ColorPicker
                  open={colorPickerModalOpen}
                  handleClose={() => setColorPickerModalOpen(false)}
                  onChange={(color) => {
                    setValue("color", color);
                  }}
                />
                <TextField
                  label={i18n.t("queueModal.form.command.name")}
                  name="command"
                  error={errors.command && Boolean(errors.command)}
                  helperText={errors.command ? errors.command.message : ""}
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <CustomToolTip
                        title={i18n.t("queueModal.form.command.tooltip.title")}
                        content={i18n.t(
                          "queueModal.form.command.tooltip.content",
                        )}
                      >
                        <HelpOutlineOutlined />
                      </CustomToolTip>
                    ),
                  }}
                  {...register("command")}
                />
                <div>
                  <TextField
                    label={i18n.t("queueModal.form.greetingMessage")}
                    type="greetingMessage"
                    multiline
                    inputRef={greetingRef}
                    minRows={5}
                    fullWidth
                    name="greetingMessage"
                    error={errors.greetingMessage}
                    helperText={
                      errors.greetingMessage
                        ? errors.greetingMessage.message
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    {...register("greetingMessage")}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                {!queueId && (
                  <Box display={"flex"} alignItems={"center"}>
                    <InfoOutlined />
                    <Typography variant={"caption"}>
                      Obs: Para criar um fluxo, é necessário criar primeiro a
                      fila.
                    </Typography>
                  </Box>
                )}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("queueModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {queueId
                    ? `${i18n.t("queueModal.buttons.okEdit")}`
                    : `${i18n.t("queueModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Box>
          </form>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Box minHeight={"60vh"} display={"flex"} flexDirection={"column"}>
            <DialogContent dividers>
              {isLoadingFlow ? (
                <Box
                  display={"flex"}
                  flex={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CircularProgress />
                </Box>
              ) : (
                  <Flow
                    flow={watch("flow")}
                    queueId={queueId}
                    handleCreate={handleCreate}
                  />
                )}
            </DialogContent>
            <DialogActions>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography variant={"caption"}>
                  Obs: Uma etapa com ação sempre será definida como o final do
                  fluxo, ou seja, não poderá criar novas etapas a partir dela.
                </Typography>
                {watch("flow") && (
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => setConfirmDeleteModal(true)}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            </DialogActions>
          </Box>
        </TabPanel>
      </Dialog>
    </div>
  );
};

export default QueueModal;
