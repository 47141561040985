import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { i18n } from "../../translate/i18n";
import { useAuthUser } from "../../context/Auth";
import api from "../../services/api";
import { socket } from "../../services/socket-io";

export function InactiveUserDialog({ children }) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { user, isLoading } = useAuthUser();

  const onClose = async () => {
    setSubmitting(true);
    if (!user) return;
    socket.connect();
    await api.put(`/users/${user.id}`, {
      isOnline: true,
    });
    setSubmitting(false);
  };

  if (!user) {
    return <>{children}</>;
  }

  return (
    <>
      <Dialog
        open={!user.isOnline && !isLoading}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant={"subtitle1"}>
              {i18n.t("inactive.title")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant={"body2"}>
              {i18n.t("inactive.message")}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          {isSubmitting && <CircularProgress size={"1rem"} />}
          <Button
            disabled={isSubmitting}
            variant={"contained"}
            color={"primary"}
            onClick={onClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
}
