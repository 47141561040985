import { Dialog, DialogActions, Button } from "@material-ui/core";
import React, { useState } from "react";

import { ColorBox } from "material-ui-color";

const ColorPicker = ({ onChange, currentColor, handleClose, open }) => {
  const [selectedColor, setSelectedColor] = useState(currentColor);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="xs"
    >
      <ColorBox
        disableAlpha={true}
        hslGradient={false}
        style={{ margin: "20px auto 0" }}
        value={selectedColor}
        onChange={(val) => {
          setSelectedColor(val);
          onChange(`#${val.hex}`);
        }}
      />
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPicker;
