import React from "react";
import { Can } from "../components/Can";
import { ListSubheader, Badge } from "@material-ui/core";
import { ListItemLink } from "./MainListItems";
import { i18n } from "../translate/i18n";
import {
  ChatBubbleOutlineOutlined,
  SyncAlt,
  PeopleAltOutlined,
  SettingsOutlined,
  AccountTreeOutlined,
  Autorenew,
} from "@material-ui/icons";

export function MenuAdminList({ user, connectionWarning }) {
  return (
    <Can
      role={user.profile}
      perform="drawer-admin-items:view"
      yes={() => (
        <>
          <ListSubheader inset>
            {i18n.t("mainDrawer.listItems.administration")}
          </ListSubheader>
          <ListItemLink
            to="/BulkMessage"
            primary={i18n.t("mainDrawer.listItems.campaigns")}
            icon={<ChatBubbleOutlineOutlined />}
          />

          <ListItemLink
            to="/rotations"
            primary={i18n.t("mainDrawer.listItems.rotations")}
            icon={<Autorenew />}
          />

          <ListItemLink
            to="/connections"
            primary={i18n.t("mainDrawer.listItems.connections")}
            icon={
              <Badge
                overlap="rectangular"
                badgeContent={connectionWarning ? "!" : 0}
                color="error"
              >
                <SyncAlt />
              </Badge>
            }
          />
          <Can
            role={user.profile}
            perform={"queue:owner"}
            yes={() => (
              <ListItemLink
                to="/queues"
                primary={i18n.t("mainDrawer.listItems.queues")}
                icon={<AccountTreeOutlined />}
              />
            )}
          />
          <ListItemLink
            to="/users"
            primary={i18n.t("mainDrawer.listItems.users")}
            icon={<PeopleAltOutlined />}
          />
          <ListItemLink
            to="/settings"
            primary={i18n.t("mainDrawer.listItems.settings")}
            icon={<SettingsOutlined />}
          />
        </>
      )}
    />
  );
}
