import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  InputLabel,
  Typography,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { green } from "@material-ui/core/colors";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  info: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },

  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export function InfoModal({ handleClose, contact }) {
  const classes = useStyles();
  const [extras, setExtras] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  const platforms = {
    whatsapp: "WhatsApp",
  };

  React.useEffect(() => {
    const fetchInit = async () => {
      try {
        setLoading(true);
        setExtras([]);
        const { data } = await api.get(`/contacts/${contact.id}/extras`);
        setLoading(false);
        setExtras(data);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    };

    fetchInit();
  }, [contact]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">
        {i18n.t("kanban.info.title")}
      </DialogTitle>
      <DialogContent>
        <div className={classes.multFieldLine}>
          <div className={classes.info}>
            <div className={classes.multFieldLine}>
              <InputLabel htmlFor={"name"}>
                {i18n.t("kanban.info.name")}
              </InputLabel>
              <Typography id={"name"}>{contact.name}</Typography>
            </div>
            <div className={classes.multFieldLine}>
              <InputLabel htmlFor={"name"}>
                {i18n.t("kanban.info.number")}
              </InputLabel>
              <Typography id={"name"}>{contact.number}</Typography>
            </div>
            {platforms[contact.platform] && (
              <div className={classes.multFieldLine}>
                <InputLabel htmlFor={"platform"}>
                  {i18n.t("kanban.info.platform")}
                </InputLabel>
                <Typography id={"platform"}>
                  {platforms[contact.platform]}
                </Typography>
              </div>
            )}
            {contact.isEdited && (
              <div className={classes.multFieldLine}>
                <Typography id={"edited"}>
                  {i18n.t("kanban.info.edited")}
                </Typography>
              </div>
            )}
          </div>
          <Avatar className={classes.large} src={contact.profilePicUrl} />
        </div>
        <div>
          <Typography
            style={{ marginBottom: 8, marginTop: 12 }}
            variant="subtitle1"
          >
            {i18n.t("contactModal.form.extraInfo")}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            extras.map((extra) => <Typography>{extra.value}</Typography>)
          )}
          {extras.length === 0 && <Typography>N/A</Typography>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          className={classes.btnWrapper}
          onClick={handleClose}
        >
          {i18n.t("kanban.info.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
