import React from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import { Tooltip } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import toastError from "../../errors/toastError";
import { useAuthUser } from "../../context/Auth";
import { useStyles } from "./styles";
import { Facebook, Instagram, WhatsApp } from "@material-ui/icons";
import { useTickets } from "../../context/Tickets";

export const TicketListItem = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { ticketId } = useParams();
  const { setTicket } = useTickets();
  const { user } = useAuthUser();

  const handleAcceptTicket = async (id) => {
    try {
      setLoading(true);
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user.id,
      });
      setTicket({
        ...ticket,
        status: "open",
        user,
      });
      setLoading(false);
      history.push(`/tickets/${id}`);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleSelectTicket = (id) => {
    setTicket(ticket);
    history.push(`/tickets/${id}`);
  };

  function BadgePlatform({ platform }) {
    if (platform === "whatsapp") {
      return <WhatsApp className={classes.whatsapp} />;
    }

    if (platform === "facebook") {
      return <Facebook className={classes.facebook} />;
    }

    if (platform === "instagram") {
      return <Instagram className={classes.instagram} />;
    }
    return <></>;
  }

  return (
    <React.Fragment key={ticket.id}>
      <ListItem
        dense
        button
        onClick={() => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket.id);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{
              backgroundColor: ticket.queue?.color || "#7C7C7C",
            }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <Badge
            overlap={"circular"}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={<BadgePlatform platform={ticket.platform} />}
          >
            <Avatar src={ticket?.contact?.profilePicUrl} />
          </Badge>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {ticket.contact.name}
                {user.profile !== "member" &&
                  ticket.user &&
                  ` - ${ticket.user.name}`}
              </Typography>
              {ticket.status === "closed" && (
                <Badge
                  overlap={"rectangular"}
                  className={classes.closedBadge}
                  badgeContent={"closed"}
                  color="primary"
                />
              )}
              {ticket.lastMessage && (
                <Typography
                  className={classes.lastMessageTime}
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                  ) : (
                    <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                  )}
                </Typography>
              )}
              {ticket.whatsappId && (
                <div
                  className={classes.userTag}
                  title={i18n.t("ticketsList.connectionTitle")}
                >
                  {ticket.whatsapp?.name}
                </div>
              )}
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {ticket.lastMessage ? (
                  <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                ) : (
                  <br />
                )}
              </Typography>

              <Badge
                overlap={"rectangular"}
                className={classes.newMessagesCount}
                badgeContent={
                  ticketId === ticket.id ? 0 : ticket.unreadMessages
                }
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }
        />
        {ticket.status === "pending" && (
          <ButtonWithSpinner
            color="primary"
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={(_) => handleAcceptTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.accept")}
          </ButtonWithSpinner>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};
