// import openSocket from "socket.io-client";
import openSocket, { io } from "socket.io-client";

import { getBackendUrl } from "../config";

function connectToSocket() {
  return openSocket(getBackendUrl());
}

const URL = getBackendUrl();
export const socket = io(URL, {
  reconnectionAttempts: 10,
  query: {
    token: JSON.parse(localStorage.getItem("token")),
  },
});

export default connectToSocket;
