import React, { createContext, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { useLocalStorage } from "../../util/";
import toastError from "../../errors/toastError";
import { socket } from "../../services/socket-io";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.response) return Promise.reject(error);
      if (error.response.status !== 401 && error.response.status !== 403)
        return Promise.reject(error);

      useLocalStorage.remove("token");
      api.defaults.headers.Authorization = undefined;
      setUser(null);
      history.push("/login");
      return Promise.reject(error);
    },
  );

  const handleEventDisconnectUser = React.useCallback(
    async (event) => {
      try {
        event.preventDefault();
        if (!user) return;
        await api.put(`/users/${user.id}`, {
          isOnline: false,
        });
      } catch (err) {
        return;
      }
    },
    [user],
  );

  const handleLogout = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await api.delete("/auth/logout");
      useLocalStorage.remove("token");
      api.defaults.headers.Authorization = undefined;
      setUser(null);
      await handleEventDisconnectUser();
      history.push("/login");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toastError(err);
      return;
    }
  }, [history, handleEventDisconnectUser]);

  useEffect(() => {
    let isMounted = false;
    const handleRefreshToken = async () => {
      if (isMounted) return;
      setIsLoading(true);

      try {
        const token = useLocalStorage.getValue("token");

        if (!token) {
          history.push("/login");
          return;
        }
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const { data } = await api.post("/auth/refresh_token");
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        setUser(data.user);
        socket.emit("user:connect", {
          userId: data.user.id,
          companyId: data.user.companyId,
        });
      } catch (err) {
        history.push("/login");
      }
    };
    handleRefreshToken().finally(() => setIsLoading(false));
    return () => {
      isMounted = true;
    };
  }, [history]);

  useEffect(() => {
    if (!user) return;
    const onEventUser = (event) => {
      setIsLoading(true);
      const { action, data } = event;
      if (action === "update" && data.id === user.id) {
        setUser(data);
      }
      setIsLoading(false);
      return;
    };

    const onEventCompany = async (event) => {
      const { action, data } = event;
      if (action === "update") {
        if (!data.isActive) {
          await handleLogout();
        }
      }
    };

    socket.on(`user:${user.id}`, onEventUser);
    socket.on(`company:${user.companyId}`, onEventCompany);
    socket.emit("user:connect", {
      userId: user.id,
      companyId: user.companyId,
    });

    return () => {
      socket.off(`user:${user.id}`, onEventUser);
    };
  }, [user, handleLogout]);

  const handleSignIn = async (userData) => {
    setIsLoading(true);
    try {
      const { data } = await api.post("/auth/login", userData);
      useLocalStorage.setValue("token", data.token);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      socket.emit("user:connect", {
        userId: data.user.id,
        companyId: data.user.companyId,
      });
      setIsLoading(false);
      return;
    } catch (err) {
      setIsLoading(false);
      toastError(err);
      return;
    }
  };

  const setOffline = async () => {
    setUser((value) => ({ ...value, isOnline: false }));
  };

  return (
    <AuthContext.Provider
      value={{ isLoading, user, setOffline, handleSignIn, handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthUser = () => {
  return useContext(AuthContext);
};

export default AuthContext;
