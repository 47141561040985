import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";

import clsx from "clsx";

import ContactDrawer from "../../../../components/ContactDrawer";
import MessageInput from "../../../../components/MessageInput/";
import MessagesList from "../../../../components/MessagesList";
import { TagsContainer } from "../../../../components/TagContainer";

import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";

import { ReplyMessageProvider } from "../../../../context/ReplyingMessage/ReplyingMessageContext";

import { useAuthUser } from "../../../../context/Auth";

import { socket } from "../../../../services/socket-io";

import { useStyles } from "./styles";
import { useTickets } from "../../../../context/Tickets";

const Ticket = ({ ticketId }) => {
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { ticket, setTicket } = useTickets();

  const { user } = useAuthUser();

  useEffect(() => {
    if (!user || !user.companyId) return;

    const handleEventTicketSocket = (event) => {
      const [action] = event.action.split(":");
      if (action === "update" && event.data.id === ticket.id) {
        setTicket(event.data);
      }
    };

    const handleEventContactSocket = (event) => {
      const { action, contact } = event;
      if (action === "update") {
        setTicket((prevState) => {
          if (prevState.contact?.id === contact?.id) {
            return { ...prevState, contact };
          }
          return prevState;
        });
      }
    };

    const handleEmitEvent = () => {
      socket.emit("joinChatBox", ticketId);
    };

    socket.on("connect", handleEmitEvent);

    socket.on(`ticket:${user.companyId}:${ticketId}`, handleEventTicketSocket);

    socket.on("contact", handleEventContactSocket);

    return () => {
      socket.off("contact", handleEventContactSocket);
      socket.off(
        `ticket:${user.companyId}:${ticketId}`,
        handleEventTicketSocket,
      );
      socket.off("connect", handleEmitEvent);
    };
  }, [ticketId, history, user, ticket, setTicket]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  if (!ticket) {
    history.push("/tickets");
    return <></>;
  }

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={ticket.contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} />
          </div>
        </TicketHeader>
        <Paper>
          <TagsContainer ticket={ticket} />
        </Paper>
        <ReplyMessageProvider>
          <MessagesList
            ticketId={ticketId}
            isGroup={ticket.isGroup}
          ></MessagesList>
          <MessageInput ticketStatus={ticket.status} />
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={ticket.contact}
      />
    </div>
  );
};

export default Ticket;
