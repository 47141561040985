export const reducer = (state, event) => {
  const { type, payload, att } = event;
  if (type === "FETCH_TICKETS") {
    return payload;
  }

  if (type === "TICKET_READ") {
    const index = state[att].tickets.findIndex(
      (ticket) => ticket.id === payload,
    );
    if (index !== -1) {
      state[att].tickets[index].unreadMessages = 0;
    }
    return state;
  }

  if (type === "NEW_TICKET") {
    state[payload.status].tickets.push(payload);
    state[payload.status].count += 1;

    return state;
  }
  const index = state[att].tickets.findIndex(
    (ticket) => ticket.id === payload.id || ticket.id === payload,
  );

  if (type === "UPDATE_TICKET" && index !== -1) {
    if (payload.status === att) {
      state[att].tickets[index] = payload;
    } else {
      state[att].tickets.splice(index, 1);
      state[att].count -= 1;
      state[payload.status].tickets.unshift(payload);
      state[payload.status].count += 1;
    }
    return state;
  }

  if (type === "DELETE_TICKET" && index !== -1) {
    state[att].tickets.splice(index, 1);
    state[att].count -= 1;
    return state;
  }

  if (type === "UPDATE_TICKET" && index === -1) {
    state[att].tickets.push(payload);
    state[att].count += 1;
  }

  return state;
};
