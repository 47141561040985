import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import { i18n } from "../../translate/i18n";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { useStyles } from "./styles";
import { useWhatsApps } from "../../context/WhatsApps";
import { ItemList } from "./components/ItemList";
import { ConnectionModal } from "./components/new";
import api from "../../services/api";
import { useAuthUser } from "../../context/Auth";

export function ConnectionPage() {
  const { whatsapps: connections, loading } = useWhatsApps();
  const { user } = useAuthUser();
  const [newDialog, setNewDialog] = React.useState(false);

  const classes = useStyles();

  async function facebookLogin(data) {
    if (data.accessToken && data.id) {
      await api.post("/meta/facebook", {
        access_token: data.accessToken,
        userId: data.id,
      });
    }
  }

  async function handleRestartAllWhatsApp() {
    await api.get("/whatsappsession/restart");
  }

  return (
    <MainContainer>
      {newDialog && (
        <ConnectionModal open={true} onClose={() => setNewDialog(false)} />
      )}
      <MainHeader>
        <Title>{i18n.t("connections.title")}</Title>
        <MainHeaderButtonsWrapper>
          {user.profile === "system" && (
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleRestartAllWhatsApp}
            >
              {i18n.t("connections.buttons.restart")}
            </Button>
          )}
          <FacebookLogin
            appId="1361450334405358"
            fields="name,email,picture"
            version="17.0"
            scope="pages_read_engagement,instagram_basic,pages_manage_engagement,business_management,instagram_manage_messages"
            callback={facebookLogin}
            icon="fa-facebook"
            render={(props) => (
              <Button
                variant={"contained"}
                color={"primary"}
                disabled={true}
                onClick={props.onClick}
              >
                {i18n.t("connections.buttons.fb")}
              </Button>
            )}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setNewDialog(true)}
          >
            {i18n.t("connections.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("connections.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.status")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.session")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.lastUpdate")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.platform")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.default")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("connections.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowSkeleton />
            ) : (
              connections.map((connection) => (
                <ItemList key={connection.id} connection={connection} />
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
}
