import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { capitalize } from "lodash";
import { useAuthUser } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, reload }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthUser();

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
  };

  const [currentContact, setCurrentContact] = useState(null);
  const [contacts, setContacts] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(ScheduleSchema),
  });

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    try {
      (async () => {
        const { data: contactData } = await api.get("/contacts");
        let customList = contactData.contacts.map((c) => ({
          id: c.id,
          name: `${c.name} - ${c.number} - ${c.platform
            .charAt(0)
            .toUpperCase()}${c.platform.slice(1)}`,
        }));
        if (Array.isArray(customList)) {
          setContacts(customList);
        }

        if (!scheduleId) return;

        const { data } = await api.get(`/schedules/${scheduleId}`);
        reset({
          ...data,
          sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
        });
      })();
    } catch (err) {
      toastError(err);
    }
  }, [scheduleId, contactId, open, reset]);

  const handleClose = () => {
    onClose();
    reset(initialState);
  };

  const handleSaveSchedule = async (values) => {
    const scheduleData = { ...values, userId: user.id };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
      } else {
        await api.post("/schedules", scheduleData);
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload();
      }
      reset(initialState);
      history.push("/schedules");
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {watch("status") === "ERRO"
            ? "Erro de Envio"
            : `Mensagem ${capitalize(watch("status"))}`}
        </DialogTitle>
        <form onSubmit={handleSubmit(handleSaveSchedule)}>
          <DialogContent dividers>
            <div className={classes.multFieldLine}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  fullWidth
                  value={currentContact}
                  options={contacts}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    return value.id === option.id;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Contato"
                    />
                  )}
                />
              </FormControl>
            </div>
            <br />
            <div className={classes.multFieldLine}>
              <TextField
                multiline={true}
                label={i18n.t("scheduleModal.form.body")}
                error={!!errors.body}
                helperText={errors.body?.message}
                variant="outlined"
                margin="dense"
                minRows={9}
                fullWidth
                {...register("name")}
              />
            </div>
            <br />
            <div className={classes.multFieldLine}>
              <TextField
                label={i18n.t("scheduleModal.form.sendAt")}
                type="datetime-local"
                name="sendAt"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.sendAt}
                helperText={errors.sendAt?.message}
                variant="outlined"
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="secondary"
              disabled={isSubmitting}
              variant="outlined"
            >
              {i18n.t("scheduleModal.buttons.cancel")}
            </Button>
            {(watch("sentAt") === null || watch("sentAt") === "") && (
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.btnWrapper}
              >
                {scheduleId
                  ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                  : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
