import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  withStyles,
  alpha,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import {
  EditOutlined,
  ExpandMore,
  ChevronRight,
  CloseOutlined,
} from "@material-ui/icons";
import api from "../../../services/api";
import { DialogNewFlow } from "./newFlow";

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} />);

export function Flow({ flow, handleCreate, queueId }) {
  const [data, setData] = React.useState(null);
  const [item, setItem] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [defaultExpanded, setDefaultExpanded] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  const handleNewStep = async (step) => {
    setLoading(true);
    const values = {
      name: "Titulo da etapa",
      message: "Sua mensagem",
      flowStepId: step.id,
      flowId: flow.id,
    };

    setDefaultExpanded((value) => [...value, step.id, step.flowStepId]);

    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      if (["file", "actionSlug", "actionResult"].includes(key)) return;
      formData.append(key, values[key]);
    });

    await api.post("/flow/step", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const result = await api.get("/flow/step/mount/" + flow.id);

    setData(result.data);
    setLoading(false);
  };
  React.useState(() => {
    async function fetch() {
      const options = await api.get("/actions");
      setOptions(options.data);
    }
    fetch();
  }, []);

  React.useEffect(() => {
    async function fetch() {
      if (!flow) return setData(null);
      if (item) return;
      const { data } = await api.get("/flow/step/mount/" + flow.id);
      setData(data);
    }
    fetch().finally(() => setLoading(false));
  }, [item, flow]);

  function MountTree({ step, nodeId }) {
    if (!step)
      return (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => handleCreate(step)}
        >
          Criar um Fluxo
        </Button>
      );

    if (step.actionSlug) {
      return (
        <StyledTreeItem
          nodeId={step.id}
          label={
            <Box display={"flex"} alignItems={"center"}>
              {step.name}
              <EditOutlined onClick={() => setItem(step)} />
            </Box>
          }
        />
      );
    }

    return (
      <StyledTreeItem
        nodeId={step.id}
        label={
          <Box display={"flex"} alignItems={"center"}>
            {step.name}
            <EditOutlined onClick={() => setItem(step)} />
          </Box>
        }
      >
        {step.steps && step.steps.length ? (
          step.steps.map((e, i) => (
            <MountTree key={e.id} step={e} nodeId={i + (nodeId + 1)} />
          ))
        ) : (
          <></>
        )}

        {!step.actionSlug && (
          <Box pt={1}>
            <Button
              size={"small"}
              variant={"outlined"}
              onClick={() => handleNewStep(step)}
            >
              Adicionar uma etapa
            </Button>
          </Box>
        )}
      </StyledTreeItem>
    );
  }
  return (
    <Box flex={1} display={"flex"} height={"100%"}>
      {isLoading ? (
        <Box
          display={"flex"}
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {item && (
            <DialogNewFlow
              options={options}
              data={data}
              item={item}
              queueId={queueId}
              handleClose={() => setItem(null)}
            />
          )}
          <TreeView
            defaultExpanded={defaultExpanded}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            defaultEndIcon={<CloseOutlined />}
          >
            <MountTree step={data} nodeId={0} />
          </TreeView>
        </>
      )}
    </Box>
  );
}
