import React from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { socket } from "../../services/socket-io";
import { useAuthUser } from "../Auth";
import { reducer } from "./reducer";

export const QueuesContext = React.createContext();

export function QueuesProvider({ children }) {
  const [queues, dispatch] = React.useReducer(reducer, []);
  const [mounted, setMounted] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const { user } = useAuthUser();

  React.useEffect(() => {
    const controller = new AbortController();

    async function fetchQueues() {
      try {
        if (mounted || !user) return;
        const { data } = await api.get("/queue", {
          signal: controller.signal,
        });

        setMounted(true);
        dispatch({ type: "LOAD_QUEUES", payload: data });
        return;
      } catch (err) {
        toastError(err);
        return;
      }
    }

    if (!user || !user.companyId) return;
    const handleEventSocket = (event) => {
      if (event.action === "update" || event.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: event.data });
      }

      if (event.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: event.data });
      }
    };
    socket.on(`queue:${user.companyId}`, handleEventSocket);

    fetchQueues().finally(() => setLoading(false));

    return () => {
      controller.abort();
      socket.off(`queue:${user.companyId}`, handleEventSocket);
    };
  }, [user, mounted]);

  return (
    <QueuesContext.Provider value={{ queues, isLoading }}>
      {children}
    </QueuesContext.Provider>
  );
}

export function useQueues() {
  return React.useContext(QueuesContext);
}
