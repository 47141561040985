import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useController } from "react-hook-form";
import api from "../../services/api";
import toastError from "../../errors/toastError";

export function SelectMembers({ control, name, queueId }) {
  const [members, setMembers] = React.useState([]);

  const { field } = useController({
    control,
    name,
  });

  React.useEffect(() => {
    async function fetch() {
      try {
        const { data } = await api.get("/users/queue/" + queueId);
        setMembers(data);
      } catch (err) {
        toastError(err);
      }
    }

    fetch();
  }, [queueId]);

  return (
    <FormControl variant={"outlined"} fullWidth>
      <InputLabel id={"select-member-label"}>Membros</InputLabel>
      <Select labelId={"select-member-label"} label={"Membros"} {...field}>
        {members.map((step) => (
          <MenuItem key={step.id} value={step.id}>
            {step.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
