import toastError from "../errors/toastError";

export const useLocalStorage = {
  getValue(key, initialValue = null) {
    try {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : initialValue;
    } catch (err) {
      toastError("Ocorreu um erro Inesperado.");
      return initialValue;
    }
  },

  setValue(key, data) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (err) {
      toastError("Ocorreu um erro Inesperado.");
    }
  },

  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      toastError("Ocorreu um erro Inesperado.");
    }
  },
};
