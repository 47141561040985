import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import { DeleteOutline } from "@material-ui/icons";
import { IconButton, TableCell } from "@material-ui/core";

import { socket } from "../../services/socket-io";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import toastError from "../../errors/toastError";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import PlanModal from "../../components/ProductModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";

const reducer = (state, action) => {
  if (action.type === "LOAD_PLANS") {
    const products = action.payload;
    const newProducts = [];

    products.forEach((product) => {
      const productIndex = state.findIndex((p) => p.id === product.id);
      if (productIndex !== -1) {
        state[productIndex] = product;
      } else {
        newProducts.push(product);
      }
    });

    return [...state, ...newProducts];
  }

  if (action.type === "UPDATE_PLANS") {
    const product = action.payload;
    const productIndex = state.findIndex((p) => p.id === product.id);

    if (productIndex !== -1) {
      state[productIndex] = product;
      return state;
    } else {
      return [product, ...state];
    }
  }

  if (action.type === "DELETE_PLANS") {
    const productId = action.payload;

    const productIndex = state.findIndex((p) => p.id === productId);
    if (productIndex !== -1) {
      state.splice(productIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Plans = () => {
  const classes = useStyles();

  const [plans, dispatch] = useReducer(reducer, []);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [deletingProduct, setDeletingProduct] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      try {
        const { data } = await api.get("/plans/list");
        dispatch({ type: "LOAD_PLANS", payload: data.plans });
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const handleSocketEvent = (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PLANS", payload: data.plan });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_PLANS", payload: data.planId });
      }
    };
    socket.on("plan", handleSocketEvent);

    return () => {
      socket.off("plan", handleSocketEvent);
    };
  }, []);

  const handleOpenProductModal = () => {
    setSelectedProduct(null);
    setProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setSelectedProduct(null);
    setProductModalOpen(false);
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setProductModalOpen(true);
  };

  const handleDeleteProduct = async (deletingId) => {
    try {
      await api.delete(`/plans/delete/${deletingId}`);
      toast.success(i18n.t("product.confirmation.delete"));
    } catch (err) {
      toastError(err);
    }
    setDeletingProduct(null);
  };

  return (
    <MainContainer>
      <PlanModal
        open={productModalOpen}
        onClose={handleCloseProductModal}
        aria-labelledby="form-dialog-title"
        planId={selectedProduct && selectedProduct.id}
      />
      <ConfirmationModal
        title={deletingProduct && `${i18n.t("plans.plansModal.title")}`}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteProduct(deletingProduct.id)}
      >
        {i18n.t("plans.confirmModal.delete")}
      </ConfirmationModal>
      <MainHeader>
        <Title>{i18n.t("plans.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            onClick={handleOpenProductModal}
            variant="contained"
            color="primary"
          >
            {i18n.t("plans.buttons.create")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("plans.grid.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("plans.grid.companies")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("plans.grid.permissions")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("plans.grid.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans &&
              plans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell align="center">{plan.name}</TableCell>
                  <TableCell align="center">{plan._count.companies}</TableCell>
                  <TableCell align="center">
                    <p>
                      {i18n.t("plans.grid.cellConnections")}: {plan.connections}
                    </p>
                    <p>
                      {i18n.t("plans.grid.cellTags")}: {plan.tags}
                    </p>
                    <p>
                      {i18n.t("plans.grid.cellQueues")}: {plan.queues}
                    </p>
                    <p>
                      {i18n.t("plans.grid.cellTickets")}: {plan.tickets}
                    </p>
                    <p>
                      {i18n.t("plans.grid.cellDuration")}: {plan.duration}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditProduct(plan)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setDeletingProduct(plan);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {loading && <TableRowSkeleton columns={6} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Plans;
