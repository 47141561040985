import React from "react";
import { Grid, Hidden, Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { TicketManager } from "./components/TicketManager";
import Ticket from "./components/Ticket";

import { i18n } from "../../translate/i18n";

import { useStyles } from "./styles";

export function TicketsPage() {
  const classes = useStyles();
  const { ticketId } = useParams();
  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0}>
          {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            <TicketManager />
          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {ticketId ? (
              <Ticket ticketId={ticketId} />
            ) : (
                <Hidden only={["sm", "xs"]}>
                  <Paper className={classes.welcomeMsg}>
                    {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                    <span>{i18n.t("chat.noTicketMessage")}</span>
                  </Paper>
                </Hidden>
              )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
