import React from "react";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import {
  CheckCircle,
  DeleteOutline,
  Edit,
  Facebook,
  Instagram,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar,
} from "@material-ui/icons";
import { format, parseISO } from "date-fns";
import { useStyles } from "../../styles";
import { ActionButton } from "../WhatsAppItem/actionButton";
import { i18n } from "../../../../translate/i18n";

export function FacebookItem({ whatsApp, handleEdit, handleConfirm }) {
  const classes = useStyles();

  const ActionsButtons = {
    DISCONNECTED: {
      label: i18n.t("connections.meta.facebook.loginAgain"),
      disabled: true,
    },
    CONNECTED: {
      label: i18n.t("connections.buttons.disconnect"),
      onClick: () => {
        handleConfirm("disconnect", whatsApp.id);
      },
    },
  };

  const renderActionButtons = (status) => {
    let action = ActionsButtons[status];
    if (!action) return <></>;
    if (!Array.isArray(action)) {
      action = [action];
    }
    return action.map((props, i) => (
      <ActionButton
        key={i}
        label={props.label}
        color={props.color}
        disabled={props.disabled}
        onClick={props.onClick}
        variant={props.variant}
      />
    ));
  };

  const renderStatusToolTips = (status) => {
    return (
      <div className={classes.customTableCell}>
        {status === "DISCONNECTED" && (
          <SignalCellularConnectedNoInternet0Bar color="secondary" />
        )}
        {status === "CONNECTED" && (
          <SignalCellular4Bar style={{ color: green[500] }} />
        )}
      </div>
    );
  };

  return (
    <TableRow key={whatsApp.id}>
      <TableCell align="center">{whatsApp.name}</TableCell>
      <TableCell align="center">
        {renderStatusToolTips(whatsApp.status)}
      </TableCell>
      <TableCell align="center">
        {renderActionButtons(whatsApp.status)}
      </TableCell>
      <TableCell align="center">
        {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
      </TableCell>
      <TableCell align="center">
        {whatsApp.id.includes("FACEBOOK") ? <Facebook /> : <Instagram />}
      </TableCell>
      <TableCell align="center">
        {whatsApp.isDefault && (
          <div className={classes.customTableCell}>
            <CheckCircle style={{ color: green[500] }} />
          </div>
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          onClick={() => {
            handleEdit(whatsApp.id);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleConfirm("delete", whatsApp.id);
          }}
        >
          <DeleteOutline />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
