import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Tooltip,
  makeStyles,
  Button,
  CardActions,
} from "@material-ui/core";
import { useTickets } from "../../context/Tickets";
import { useAuthUser } from "../../context/Auth";
import { i18n } from "../../translate/i18n";
import { InfoModal } from "./InfoModal";
import { format, parseISO, isSameDay } from "date-fns";
import Confirm from "../../components/ConfirmationModal";

const useStyles = makeStyles(() => ({
  ticketQueueColor: {
    width: "8px",
    height: "100%",
  },
  button: {
    cursor: "pointer",
  },
}));

export function KanbanItem({ item, onRemove }) {
  const [info, setInfo] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const { user } = useAuthUser();
  const { getTicketById } = useTickets();
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
      data: { type: "Item", item },
      disabled: !["admin", "owner"].includes(user.profile),
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const STATUS = {
    open: i18n.t("kanban.ticket.open"),
    pending: i18n.t("kanban.ticket.pending"),
    closed: i18n.t("kanban.ticket.closed"),
  };

  return (
    <Box p={1} display={"flex"} ref={setNodeRef} style={style}>
      <Confirm
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        title={i18n.t("kanban.item.remove")}
        children={i18n.t("kanban.item.remove_message")}
        onConfirm={() => onRemove(item)}
      />
      {info && (
        <InfoModal handleClose={() => setInfo(false)} contact={item.contact} />
      )}

      {item.queue && (
        <Tooltip arrow placement="right" title={item.queue.name || "Sem fila"}>
          <span
            style={{
              backgroundColor: item.queue.color,
            }}
            className={classes.ticketQueueColor}
          />
        </Tooltip>
      )}
      <Box width={"100%"}>
        <Card>
          <CardContent
            className={classes.button}
            {...attributes}
            {...listeners}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Typography variant={"h6"}>
                {item.contact?.name || "Contact Name"}{" "}
                {item.user ? ` - ${item.user.name}` : ""}
              </Typography>
              <Typography
                variant={"caption"}
              >{`Ticket #${item.id}`}</Typography>
              <Typography variant={"caption"}>
                Tags: {item.tags.map((tag) => tag.name).join(", ") || "N/A"}
              </Typography>
              <Typography variant={"caption"}>
                Status: {STATUS[item.status] || "N/A"}
              </Typography>
              <Typography variant={"caption"}>
                {i18n.t("kanban.item.created")}:{" "}
                {isSameDay(parseISO(item.createdAt), new Date()) ? (
                  <>{format(parseISO(item.createdAt), "HH:mm")}</>
                ) : (
                  <>{format(parseISO(item.createdAt), "dd/MM/yyyy")}</>
                )}
              </Typography>
            </Box>
          </CardContent>

          <CardActions>
            <Button
              size={"small"}
              color={"primary"}
              onClick={() => getTicketById(item.id)}
            >
              {i18n.t("kanban.item.ticket")}
            </Button>
            <Button
              size={"small"}
              color={"primary"}
              onClick={() => setInfo(true)}
            >
              {i18n.t("kanban.item.info")}
            </Button>
            <Button
              size={"small"}
              color={"secondary"}
              variant={"outlined"}
              onClick={() => setConfirmDialog(true)}
            >
              {i18n.t("kanban.item.remove")}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
}
