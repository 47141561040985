import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

const toastError = (err) => {
  if (err.code && err.code === "ERR_CANCELED") {
    return;
  }
  const errorMsg = err.response?.data?.message || err.response?.data?.error;
  if (errorMsg) {
    if (i18n.exists(`backendErrors.${errorMsg}`)) {
      toast.error(i18n.t(`backendErrors.${errorMsg}`), {
        toastId: errorMsg,
      });
    } else {
      toast.error(errorMsg, {
        toastId: errorMsg,
      });
    }
  } else {
    toast.error("An error occurred!");
  }
};

export default toastError;
