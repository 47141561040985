import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useQueues } from "../../../context/Queues";
import { SelectMembers } from "../../../components/SelectMembers";

export function OptionResult({
  slug,
  setValue,
  watch,
  index,
  queueId,
  control,
  property = null,
  steps = [],
}) {
  const { queues } = useQueues();

  const actionsComponent = {
    "action-transfer-to-queue": (
      <FormControl variant={"outlined"} fullWidth>
        <InputLabel id={"select-queue-label"}>Queue</InputLabel>
        <Select
          labelId={"select-queue-label"}
          label={"Queue"}
          name={property ? property : `options.${index}.actionResult`}
          value={watch(property ? property : `options.${index}.actionResult`)}
          onChange={({ target }) => {
            setValue(
              property ? property : `options.${index}.actionResult`,
              target.value,
            );
          }}
        >
          {queues.map((queue) => (
            <MenuItem key={queue.id} value={queue.id}>
              {queue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    "action-go-to-flow-step": (
      <FormControl variant={"outlined"} fullWidth>
        <InputLabel id={"select-queue-label"}>Etapas</InputLabel>
        <Select
          labelId={"select-queue-label"}
          label={"Etapas"}
          name={property ? property : `options.${index}.actionResult`}
          value={watch(property ? property : `options.${index}.actionResult`)}
          onChange={({ target }) => {
            setValue(
              property ? property : `options.${index}.actionResult`,
              target.value,
            );
          }}
        >
          {steps.map((step) => (
            <MenuItem key={step.id} value={step.id}>
              {step.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    "action-transfer-specific-user": (
      <SelectMembers
        queueId={queueId}
        control={control}
        name={"actionResult"}
      />
    ),
  };

  return actionsComponent[slug] ? actionsComponent[slug] : <></>;
}
