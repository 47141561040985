import React from "react";
import {
  Dialog,
  makeStyles,
  DialogTitle,
  DialogContent,
  ListItem,
  List,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import { useTickets } from "../../context/Tickets";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

export function TicketModal({ handleClose, onTickets }) {
  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const { getAllTickets } = useTickets();

  const tickets = getAllTickets().filter((e) => !e.isKanban);

  const [checked, setChecked] = React.useState([]);

  const filterTickets = () => {
    const value = search.toLowerCase();
    const data = tickets || [];
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    if (!value) return data;
    const result = data.filter((elem) => {
      const userFilter = elem.user
        ? elem.user.name.toLowerCase().indexOf(value) > -1
        : false;

      return (
        elem.contact.name.toLowerCase().indexOf(value) > -1 ||
        elem.contact.number.toLowerCase().indexOf(value) > -1 ||
        elem.lastMessage.toLowerCase().indexOf(value) > -1 ||
        userFilter ||
        elem.tags.filter((tag) => tag.name.toLowerCase().indexOf(value) > -1)
          .length > 0
      );
    });
    return result;
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddTickets = async () => {
    try {
      setLoading(true);
      const { data } = await api.put("/kanban/ticket", {
        tagId: null,
        isKanban: true,
        ticketId: checked,
      });

      onTickets(data);
      handleClose();
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const STATUS = {
    open: i18n.t("kanban.ticket.open"),
    pending: i18n.t("kanban.ticket.pending"),
    closed: i18n.t("kanban.ticket.closed"),
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={true}
        onClose={() => handleClose()}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {i18n.t("kanban.ticket.title")}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
          <List>
            {filterTickets().map((ticket, i) => (
              <ListItem
                button
                dense
                key={`${ticket.id}${i}`}
                onClick={handleToggle(ticket.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge={"start"}
                    checked={checked.indexOf(ticket.id) !== -1}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemAvatar>
                  <Avatar src={ticket.contact.profilePicUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${ticket.contact.name} - ${ticket.contact.number}`}
                  secondary={`${STATUS[ticket.status] || ""}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={handleClose}
            disabled={isLoading}
          >
            {i18n.t("kanban.ticket.cancel")}
          </Button>
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={handleAddTickets}
            disabled={isLoading}
          >
            {i18n.t("kanban.ticket.add")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
