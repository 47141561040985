import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { getAppMaintenanceMode } from "./config";
import { Box, Typography } from "@material-ui/core";
import { Build } from "@material-ui/icons";
import { i18n } from "./translate/i18n";

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#2576d2" },
      },
    },
    locale,
  );

  useEffect(() => {
    //const i18nlocale = localStorage.getItem("i18nextLng");
    //const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    //if (browserLocale === "ptBR") {
    //  setLocale(ptBR);
    //}
    setLocale(ptBR);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {getAppMaintenanceMode() === "true" ? (
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
          width={"100vw"}
        >
          <Build fontSize={"large"} />
          <Typography variant={"h3"}>{i18n.t("maintenance.title")}</Typography>
          <Typography variant={"subtitle1"}>
            {i18n.t("maintenance.description")}
          </Typography>
        </Box>
      ) : (
        <Routes />
      )}
    </ThemeProvider>
  );
};

export default App;
