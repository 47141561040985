const messages = {
  en: {
    translations: {
      flow: {
        confirmModal: "This action cannot be undone.",
      },
      inactive: {
        title: "Offline Due to Inactivity",
        message:
          'You are currently offline due to inactivity. To restore the connection and get back online, simply click the "OK" button.',
      },
      kanban: {
        column: "Add Column",
        info: {
          title: "User Details",
          name: "Name",
          number: "Number",
          edited: "Editable",
          platform: "Platform",
          close: "Close",
        },
        item: {
          ticket: "View Ticket",
          info: "Info",
          remove: "Remove",
          created: "Created",
          updated: "Updated",
          remove_message: "Are you sure?",
        },
        ticket: {
          open: "Working on",
          closed: "Resolved",
          pending: "Queue",
          title: "Tickets",
          cancel: "Cancel",
          add: "Add Tickets",
        },
      },
      general: {
        field: {
          required: "This field is required",
        },
        buttons: {
          save: "save",
          add: "add",
          close: "close",
          delete: "delete",
          new: "new",
          cancel: "cancel",
        },
      },
      maintenance: {
        title: "Maintenance in Progress",
        description:
          "We're currently performing scheduled maintenance on our website. We apologize for the inconvenience and expect to be back up and running shortly.",
      },
      stepOptions: {
        name: "Step name",
        message: "Message",
        buttons: {
          delete: "delete",
        },
      },
      multiSelect: {
        inputLabel: "Options",
      },
      rotations: {
        title: "Rotations",
        table: {
          number: "Number",
          groups_count: "Total groups",
          groups: "Groups",
          limits: "Ticket limit",
          actions: "Actions",
          active: "active",
          disabled: "idle",
          groups_status: "Status",
          wpp_name: "WhatsApp Name",
          today: "Tickets - Today",
          users: "members online",
          name: "Name",
          queue: "Queue",
        },
        buttons: {
          add: "new rotation",
          disabled: "all queues used",
        },
        modal: {
          title: {
            add: "create a new rotation",
            edit: "edit rotation",
            delete: "are you sure?",
            report: "Today's report - ",
          },
          message: {
            delete: "this action cannot be reversed",
          },
          groups: {
            queue: "queue",
            maxTicket: "ticket limit",
            queue_label: "Choose a queue",
            maxTicket_warn: "unlimited",
          },
          duplication: "cannot have duplicate queues",
          add: "add",
          create: "create",
          edit: "save",
        },
      },
      plans: {
        buttons: {
          create: "New Plan",
          add: "Add",
          edit: "Save",
          cancel: "Cancel",
        },
        title: "Plans",
        plansModal: {
          created: "Register a new Plan",
          edited: "Edit plan",

          name: "Name",
          connections: "Connections",
          tickets: "Tickets",
          queues: "Queues",
          tags: "Tags",
          duration: "Duration (days)",
        },
        confirmModal: {
          delete: "This action cannot be reversed.",
        },
        grid: {
          name: "Name",
          companies: "Companies",
          permissions: "Permissions",
          actions: "Actions",

          cellConnections: "Connections",
          cellTags: "Tags",
          cellQueues: "Queues",
          cellTickets: "Tickets",
          cellDuration: "Duration (days)",
        },
        createdAt: "Successfully created new plan",
        updatedAt: "Successfully updated plan",
        deletePlan: "This actions cannot be reversed.",
        deleteTitle: "Are you sure you want to delete ",
      },
      company: {
        buttons: {
          addCompany: "New Company",
        },
        title: "Companies",
        companyModal: {
          titleAdd: "Register a new company",
          titleEdit: "Edit Company",
          name: "Admin Name",
          email: "Admin Email",
          password: "Admin Password",
          companyName: "Company Name",
          plan: "Plan",
          required: "This property is required",
          invalidEmail: "Email is invalid",
          status: "Status",
          statusActive: "Active",
          statusInactive: "Inactive",
        },
        plan: {
          connections: "Connections",
          tickets: "Tickets",
          tags: "Tags",
          queues: "Queues",
        },
        grid: {
          name: "Name",
          email: "Email",
          plan: "Plan",
          members: "Members",
          companyId: "ID",
          companyName: "Company Name",
          createdAt: "Created At",
          status: "Status",
          active: "Active",
          inactive: "Inactive",
          actions: "Actions",
        },
        createdAt: "Successfully created new company",
        updatedAt: "Successfully updated company",
        deleteCompany: "This actions cannot be reversed.",
        deleteTitle: "Are you sure you want to delete ",
      },
      schedules: {
        title: "Schedules",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this schedule?",
          deleteMessage: "This action cannot be reversed.",
        },
        table: {
          contact: "Contact",
          body: "Message",
          sendAt: "Schedule date",
          sentAt: "Send date",
          status: "Status",
          actions: "action",
        },
        buttons: {
          add: "New Schedule",
        },
        toasts: {
          deleted: "Schedule Successfully deleted.",
        },
      },
      scheduleModal: {
        title: {
          add: "New Schedule",
          edit: "Edit Schedule",
        },
        form: {
          body: "Message",
          contact: "Contact",
          sendAt: "Schedule date",
          sentAt: "Send date",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Schedule successfully saved.",
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this tag?",
          deleteMessage: "This action cannot be reversed.",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Tickets",
          actions: "Actions",
          kanban: {
            title: "Kanban",
            yes: "Yes",
            no: "No",
          },
        },
        buttons: {
          add: "New Tag",
        },
        toasts: {
          deleted: "Tag Successfully Deleted",
        },
      },
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      tagModal: {
        title: {
          add: "New Tag",
          edit: "Edit Tag",
          kanban: {
            add: "Create a new Column",
            edit: "Edit Column",
          },
        },
        delete: {
          title: "Delete",
          message: "Are you sure? It cannot be reverted.",
        },
        form: {
          name: "Name",
          color: "Color",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          delete: "Delete",
        },
        success: "Tag Successfully Saved",
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
        },
        messages: {
          inAttendance: {
            title: "In Service",
          },
          waiting: {
            title: "Waiting",
          },
          closed: {
            title: "Closed",
          },
        },
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          clearTitle: "Clear Session",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage:
            "Are you sure? You'll need to read QR Code ou Login again.",
          forceDelete:
            "Are you sure? This action will delete the system of all the tickets and messages this Connection. This action cannot be reverted.",
        },
        meta: {
          facebook: {
            loginAgain: "Please log in again",
          },
        },
        buttons: {
          add: "Add WhatsApp",
          fb: "Login with facebook",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
          pending: "Please, wait",
          restart: "Reconnect All WhatsApp",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
          platform: "Platform",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
          farewellMessage: "Farewell Message",
          tokenApi: "Token",
          rotate: "Rotate",
          command: {
            name: "Command",
            tooltip: {
              title: "Activate by command",
              content:
                "Write a command that you want to use as a trigger to start the message flow, Ex: !queues (Using a command disables the automatic message function.)",
            },
          },
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "Number or ID",
          email: "Email",
          actions: "Actions",
          platform: "Platform",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
          isEdited: "Editable",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
          command: {
            name: "Command",
            tooltip: {
              title: "Activate by command",
              content:
                "Write a command that you want to use as a trigger to start the message flow, Ex: !services",
            },
          },
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          newStep: "Add Step",
        },
      },
      colorModal: {
        confirm: "Confirm",
        cancel: "Cancel",
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
          whatsapp: "Default Connection",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a queue",
        fieldConnectionPlaceholder: "Please select a connection",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        connectionTitle: "Connection that is currently being used.",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Queues",
          administration: "Administration",
          companies: "Companies",
          plans: "Plans",
          users: "Users",
          settings: "Settings",
          tags: "Tags",
          schedules: "Schedules",
          campaigns: "Campaigns",
          supervisor: "Supervisor",
          system: "System",
          rotations: "Rotations",
          kanban: "Kanban",
          internal: "Internal Chat",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
          steps: "Steps",
        },
        options: {
          title: "edit or create options",
          description:
            "These options will be displayed in messages, accompanied by a number to select.",
          help: {
            title: "Help",
            content:
              '"label" is option name and "action" is what this option will do.',
          },
          empty:
            'The options are empty. click on "New" to create a new option.',
          form: {
            label: "Label",
            actionId: "Action",
          },
          buttons: {
            save: "Save",
            close: "Close",
            new: "New",
          },
        },
        buttons: {
          add: "Add queue",
          options: "Options",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        kanban: {
          add: "Add to Kanban",
          remove: "Remove from Kanban",
          view: "View on Kanban",
          add_success: "Added to Kanban",
          remove_success: "Removed from Kanban",
        },
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        retry: "Retry sending the message",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
        ERR_CONTAINS_TICKET:
          "It is not possible to delete this Connection for contains tickets in it. Do you want to force this action?",
        ERR_MEDIA_FORMAT_INVALID: "This media format is not valid.",
        ERR_WBOT_NOT_FOUND:
          "No Session was found. Please try to connect again.",
        ERR_WAP_NOT_CONNECTED:
          "No Session was found. Please try to connect again.",
        ERR_NUMBER_IS_NOT_FOUND: "This phone number was not found.",
        ERR_SEND_META_MESSAGE_SERVICE_TEMP_UNAVAILABLE:
          "Service temporarily unavailable",
        ERR_SEND_META_MESSAGE_OUTSIDE_ALLOWED_WINDOW:
          "This message is sent outside of allowed window. Learn more about the new policy here: https://developers.facebook.com/docs/messenger-platform/policy-overview",
        ERR_SEND_META_MESSAGE: "Unexpected error when sending the message.",
        ERR_SEND_META_MEDIA: "Unexpected error when sending the media.",
      },
    },
  },
};
export { messages };
