import React from "react";
import { ListItemLink } from "./MainListItems";
import { Can } from "../components/Can";
import { ListSubheader, Badge } from "@material-ui/core";
import { i18n } from "../translate/i18n";
import {
  ChatBubbleOutlineOutlined,
  SyncAlt,
  AccountTreeOutlined,
  PeopleAltOutlined,
  Assignment,
  SettingsOutlined,
  Business,
} from "@material-ui/icons";

export function MenuSystemList({ user, connectionWarning }) {
  return (
    <Can
      role={user.profile}
      perform="drawer-system-items:view"
      yes={() => (
        <>
          {
            // <ListItemLink
            //   to="/ShippingReport"
            //   primary="Relatório Envios"
            //   icon={<LibraryBooks />}
            // />
            //
            // <ListItemLink
            //   to="/SettingsMessage"
            //   primary="Config. Envios"
            //   icon={<AddCircleOutline />}
            // />
          }
          <ListSubheader inset>
            {i18n.t("mainDrawer.listItems.system")}
          </ListSubheader>
          <ListItemLink
            to="/BulkMessage"
            primary={i18n.t("mainDrawer.listItems.campaigns")}
            icon={<ChatBubbleOutlineOutlined />}
          />
          <ListItemLink
            to="/connections"
            primary={i18n.t("mainDrawer.listItems.connections")}
            icon={
              <Badge
                overlap="rectangular"
                badgeContent={connectionWarning ? "!" : 0}
                color="error"
              >
                <SyncAlt />
              </Badge>
            }
          />
          <ListItemLink
            to="/companies"
            primary={i18n.t("mainDrawer.listItems.companies")}
            icon={<Business />}
          />
          <ListItemLink
            to="/plans"
            primary={i18n.t("mainDrawer.listItems.plans")}
            icon={<Assignment />}
          />
          <ListItemLink
            to="/queues"
            primary={i18n.t("mainDrawer.listItems.queues")}
            icon={<AccountTreeOutlined />}
          />
          <ListItemLink
            to="/users"
            primary={i18n.t("mainDrawer.listItems.users")}
            icon={<PeopleAltOutlined />}
          />
          <ListItemLink
            to="/settings"
            primary={i18n.t("mainDrawer.listItems.settings")}
            icon={<SettingsOutlined />}
          />
        </>
      )}
    />
  );
}
