import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { InputFile } from "../../../components/InputFile";
import api from "../../../services/api";
import { OptionResult } from "./optionResult";
import toastError from "../../../errors/toastError";
import ConfirmationModal from "../../../components/ConfirmationModal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const FlowSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").required(),
  message: Yup.string().optional(),
});

export function DialogNewFlow({ options, data, item, handleClose, queueId }) {
  const [isConfirmToDelete, setConfirmToDelete] = React.useState(false);
  const [isConfirmForceAction, setConfirmForceAction] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: item || {
      name: "",
      message: "",
      file: null,
      flowId: "",
      flowStepId: "",
      actionSlug: "",
    },
    resolver: yupResolver(FlowSchema),
  });

  function unmount(value) {
    const items = [];
    const { steps, ...res } = value;

    items.push(res);

    for (const step of steps) {
      items.push(...unmount(step));
    }

    return items;
  }

  const handleConfirmation = () => setConfirmToDelete(true);

  const onDelete = async () => {
    try {
      setLoading(true);
      const values = getValues();
      if (!values.id) return;
      await api.delete(`/flow/step/${values.id}`);
      handleClose();
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const onForceAction = () => {
    setValue("steps", []);
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (values) => {
    try {
      if (
        typeof values.actionSlug === "string" &&
        values.actionSlug !== "" &&
        values.steps.length
      ) {
        setConfirmForceAction(true);
        return;
      }
      const formData = new FormData();
      formData.append("fromMe", true);
      Object.keys(values).forEach((key) => {
        if (["file", "actionSlug", "actionResult"].includes(key)) return;
        formData.append(key, values[key]);
      });

      if (typeof values.actionSlug === "string") {
        formData.append("actionSlug", values.actionSlug);
        formData.append(
          "actionResult",
          values.actionSlug ? values.actionResult : "",
        );
      }

      if (values.file.length) {
        formData.append("file", values.file[0]);
      }

      if (values.id) {
        await api.put("/flow/step", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } else {
        await api.post("/flow/step", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      handleClose();
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <ConfirmationModal
        open={isConfirmForceAction}
        onClose={() => setConfirmForceAction(false)}
        title={"Você tem certeza?"}
        children={
          "Esta etapa possui subetapas. Se adicionar uma ação, todas as subetapas serão excluídas."
        }
        onConfirm={onForceAction}
      />

      <ConfirmationModal
        open={isConfirmToDelete}
        onClose={() => setConfirmToDelete(false)}
        title={"Você tem certeza?"}
        children={"Esta ação não pode ser revertida."}
        onConfirm={isConfirmToDelete ? onDelete : onForceAction}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Box
            flex={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>{item.id ? "Editar" : "Criar"} Etapa</Typography>
            {item.id && item.flowStepId && (
              <Button
                disabled={isLoading || isSubmitting}
                onClick={handleConfirmation}
                variant={"contained"}
                color={"secondary"}
              >
                Deletar
                {(isSubmitting || isLoading) && <CircularProgress size={24} />}
              </Button>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={"flex"} gridGap={8} flexDirection={"column"}>
            <Box gridGap={2} flex={1} width={"100%"} display={"flex"}>
              <TextField
                label={"Name"}
                variant={"outlined"}
                error={errors.name}
                helperText={errors.name ? errors.name.message : ""}
                {...register("name")}
              />

              <Controller
                control={control}
                name="actionSlug"
                render={({ field }) => (
                  <FormControl variant={"outlined"} fullWidth>
                    <InputLabel id={"select-action"}>Ação</InputLabel>
                    <Select
                      defaultValue={""}
                      label={"Ação"}
                      labelId={"select-action"}
                      {...field}
                    >
                      <MenuItem key={""} value={""}>
                        Nenhuma Ação
                      </MenuItem>
                      {options.map((e) => (
                        <MenuItem key={e.slug} value={e.slug}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
            <OptionResult
              slug={watch("actionSlug")}
              watch={watch}
              property={"actionResult"}
              setValue={setValue}
              steps={unmount(data) || []}
              control={control}
              queueId={queueId}
            />
            <TextField
              multiline
              variant={"outlined"}
              minRows={5}
              label={"Message"}
              {...register("message")}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display={"flex"}
            flex={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <InputFile
              name={"file"}
              mediaInitial={watch("media")}
              control={control}
              register={register}
            />
            <Box display={"flex"} gridGap={6}>
              <Button
                disabled={isSubmitting || isLoading}
                type={"submit"}
                variant={"contained"}
                color={"primary"}
              >
                Salvar
                {(isSubmitting || isLoading) && <CircularProgress size={24} />}
              </Button>
              <Button
                disabled={isSubmitting || isLoading}
                onClick={handleClose}
                variant={"contained"}
              >
                Fechar
                {(isSubmitting || isLoading) && <CircularProgress size={24} />}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}
