import React from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from "material-ui-color";
import { green } from "@material-ui/core/colors";

import Confirm from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const TagSchema = Yup.object().shape({
  name: Yup.string().min(3, "Name muito curta").required("Obrigatório"),
  color: Yup.string().min(3, "Color muito curta").required("Obrigatório"),
  id: Yup.string().optional(),
});

export function ColumnModal({ handleClose, item }) {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [colorPickerModalOpen, setColorPickerModalOpen] = React.useState(false);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, touchedFields, isSubmitting },
  } = useForm({
    defaultValues: item || {
      name: "",
      color: "",
    },
    resolver: yupResolver(TagSchema),
  });

  const onSubmit = handleSubmit(async (values) => {
    try {
      if (values.id) {
        const { data } = await api.put("/kanban/tag", values);
        handleClose(data);
      } else {
        const { data } = await api.post("/kanban/tag", values);
        handleClose(data);
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  });

  const onDelete = async () => {
    try {
      setLoading(true);
      await api.delete(`/kanban/tag/${item.id}`);

      setLoading(false);
      handleClose(item.id);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Confirm
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        title={i18n.t("tagModal.delete.title")}
        children={i18n.t("tagModal.delete.message")}
        onConfirm={onDelete}
      />
      <Dialog
        open={true}
        onClose={() => handleClose()}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {item
            ? `${i18n.t("tagModal.title.kanban.edit")}`
            : `${i18n.t("tagModal.title.kanban.add")}`}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <div className={classes.multFieldLine}>
              <TextField
                name={"name"}
                variant={"outlined"}
                margin="dense"
                label={"Name"}
                error={errors.companyName}
                helperText={
                  errors.companyName ? errors.companyName.message : ""
                }
                fullWidth
                {...register("name")}
              />
            </div>
            <br />
            <div className={classes.multFieldLine}>
              <TextField
                fullWidth
                label={"Color"}
                name="color"
                id="color"
                error={touchedFields.color && Boolean(errors.color)}
                helperText={touchedFields.color && errors.color}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{ backgroundColor: watch("color") }}
                        className={classes.colorAdorment}
                      ></div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      size="small"
                      color="default"
                      onClick={() =>
                        setColorPickerModalOpen(!colorPickerModalOpen)
                      }
                    >
                      <Colorize />
                    </IconButton>
                  ),
                }}
                variant="outlined"
                margin="dense"
              />
            </div>

            {colorPickerModalOpen && (
              <div>
                <ColorBox
                  disableAlpha={true}
                  hslGradient={false}
                  style={{ margin: "20px auto 0" }}
                  value={watch("color")}
                  onChange={(val) => {
                    setValue("color", `#${val.hex}`);
                  }}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              color="secondary"
              disabled={isSubmitting || isLoading}
              variant="outlined"
            >
              {i18n.t("tagModal.buttons.cancel")}
            </Button>
            {item && (
              <Button
                onClick={() => setConfirmDialog(true)}
                color="secondary"
                disabled={isSubmitting || isLoading}
                variant="contained"
              >
                {i18n.t("tagModal.buttons.delete")}
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting || isLoading}
              variant="contained"
              className={classes.btnWrapper}
            >
              {item
                ? `${i18n.t("tagModal.buttons.okEdit")}`
                : `${i18n.t("tagModal.buttons.okAdd")}`}
              {(isSubmitting || isLoading) && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
