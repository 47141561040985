import React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Chip,
} from "@material-ui/core";

import { HelpOutlineOutlined } from "@material-ui/icons";

import { MultiSelect } from "../../../../components/MultiSelect";
import CustomToolTip from "../../../../components/ToolTip";

import api from "../../../../services/api";
import { i18n } from "../../../../translate/i18n";
import toastError from "../../../../errors/toastError";
import { useStyles } from "./styles";
import { useQueues } from "../../../../context/Queues";

const ConnectionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

export const ConnectionModal = ({ open, onClose, whatsAppId }) => {
  const [isLoading, setLoading] = React.useState(true);
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    farewellMessage: "",
    tokenApi: "",
    command: "",
    isDefault: false,
    isRotate: false,
    queueIds: [],
  };
  const { queues } = useQueues();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(ConnectionSchema),
    defaultValues: initialState,
  });

  React.useEffect(() => {
    let unmounted = false;
    const controller = new AbortController();
    const controllerQueue = new AbortController();
    const fetchSession = async () => {
      if (!whatsAppId || unmounted) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`, {
          signal: controller.signal,
        });
        data.queueIds = data.queues?.map((queue) => queue.id);
        reset(data);
      } catch (err) {
        toastError(err);
      }
    };

    async function main() {
      setLoading(true);
      await fetchSession();
    }

    main().finally(() => setLoading(false));

    return () => {
      unmounted = true;
      controller.abort();
      controllerQueue.abort();
    };
  }, [whatsAppId, reset]);

  const handleSaveWhatsApp = async (values) => {
    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, values);
      } else {
        await api.post("/whatsapp", values);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>
        {whatsAppId
          ? i18n.t("whatsappModal.title.edit")
          : i18n.t("whatsappModal.title.add")}
      </DialogTitle>
      <form onSubmit={handleSubmit(handleSaveWhatsApp)}>
        <DialogContent dividers>
          <div className={classes.multFieldLine}>
            <TextField
              label={i18n.t("whatsappModal.form.name")}
              autoFocus
              name="name"
              defaultValue={watch("name")}
              error={errors.name && Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : ""}
              variant="outlined"
              margin="dense"
              className={classes.textField}
              {...register("name")}
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  name="isDefault"
                  defaultChecked={watch("isDefault")}
                  {...register("isDefault")}
                />
              }
              label={i18n.t("whatsappModal.form.default")}
            />

            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  name="isRotate"
                  defaultChecked={watch("isRotate")}
                  {...register("isRotate")}
                />
              }
              label={i18n.t("whatsappModal.form.rotate")}
            />
          </div>
          <div>
            <TextField
              defaultValue={watch("greetingMessage")}
              label={i18n.t("queueModal.form.greetingMessage")}
              type="greetingMessage"
              name={"greetingMessage"}
              multiline
              minRows={5}
              fullWidth
              error={errors.greetingMessage && Boolean(errors.greetingMessage)}
              helperText={
                errors.greetingMessage ? errors.greetingMessage.message : ""
              }
              variant="outlined"
              margin="dense"
              {...register("greetingMessage")}
            />
          </div>
          <div>
            <TextField
              defaultValue={watch("farewellMessage")}
              label={i18n.t("whatsappModal.form.farewellMessage")}
              type="farewellMessage"
              multiline
              minRows={5}
              fullWidth
              name="farewellMessage"
              error={errors.farewellMessage && Boolean(errors.farewellMessage)}
              helperText={
                errors.farewellMessage ? errors.farewellMessage.message : ""
              }
              variant="outlined"
              margin="dense"
              {...register("farewellMessage")}
            />
          </div>

          <div className={classes.multFieldLine}>
            <TextField
              defaultValue={watch("tokenApi")}
              label={i18n.t("whatsappModal.form.tokenApi")}
              name="tokenApi"
              error={errors.tokenApi && Boolean(errors.tokenApi)}
              helperText={errors.tokenApi ? errors.tokenApi.message : ""}
              variant="outlined"
              margin="dense"
              className={classes.textField}
              {...register("tokenApi")}
            />
            {!watch("isRotate") && (
              <TextField
                defaultValue={watch("command")}
                label={i18n.t("whatsappModal.form.command.name")}
                name="command"
                error={errors.command && Boolean(errors.command)}
                helperText={errors.command ? errors.command.message : ""}
                variant="outlined"
                margin="dense"
                className={classes.textField}
                InputProps={{
                  endAdornment: (
                    <CustomToolTip
                      title={i18n.t("whatsappModal.form.command.tooltip.title")}
                      content={i18n.t(
                        "whatsappModal.form.command.tooltip.content"
                      )}
                    >
                      <HelpOutlineOutlined />
                    </CustomToolTip>
                  ),
                }}
                {...register("command")}
              />
            )}
          </div>
          <MultiSelect
            control={control}
            name={"queueIds"}
            options={queues}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((id) => {
                  const value = queues.find((q) => q.id === id);
                  return value ? (
                    <Chip
                      key={id}
                      variant="outlined"
                      label={value.name.split("-")[0]}
                      className={classes.chip}
                      style={{
                        background: value.color,
                      }}
                    />
                  ) : null;
                })}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={isSubmitting}
            variant="outlined"
          >
            {i18n.t("whatsappModal.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            variant="contained"
            className={classes.btnWrapper}
          >
            {whatsAppId
              ? i18n.t("whatsappModal.buttons.okEdit")
              : i18n.t("whatsappModal.buttons.okAdd")}
            {isSubmitting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
