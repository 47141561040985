import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Field, Formik, Form } from "formik";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const PlanSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  connections: Yup.number().min(0, "This number cannot be negative!"),
});

const PlanModal = ({ open, onClose, planId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    connections: 0,
    tickets: 0,
    tags: 0,
    queues: 0,
    duration: 30,
  };

  const [plan, setPlan] = useState(initialState);

  const handleClose = () => {
    setPlan(initialState);
    onClose();
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const { data } = await api.get(`/plans/${planId}`);
        setPlan(data);
      } catch (err) {
        toastError(err);
      }
    };
    if (planId) {
      fetchProduct();
    }
  }, [open, planId]);

  const handleSubmit = async (values) => {
    const productData = values;
    try {
      if (planId) {
        await api.put(`/plans/update/${planId}`, productData);
        toast.success(i18n.t("plans.updatedAt"));
      } else {
        await api.post("/plans/create", productData);
        toast.success(i18n.t("plans.createdAt"));
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {planId
            ? `${i18n.t("plans.plansModal.edited")}`
            : `${i18n.t("plans.plansModal.created")}`}
        </DialogTitle>
        <Formik
          initialValues={plan}
          enableReinitialize={true}
          validationSchema={PlanSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  name="name"
                  variant="outlined"
                  margin="dense"
                  label={i18n.t("plans.plansModal.name")}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                />
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    type="number"
                    name="connections"
                    inputProps={{
                      min: "0",
                      max: "100",
                    }}
                    variant="outlined"
                    margin="dense"
                    label={i18n.t("plans.plansModal.connections")}
                  />
                  <Field
                    as={TextField}
                    type="number"
                    name="tickets"
                    variant="outlined"
                    inputProps={{
                      min: "0",
                      max: "100",
                    }}
                    margin="dense"
                    label={i18n.t("plans.plansModal.tickets")}
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    type="number"
                    name="queues"
                    variant="outlined"
                    inputProps={{
                      min: "0",
                      max: "100",
                    }}
                    margin="dense"
                    label={i18n.t("plans.plansModal.queues")}
                  />
                  <Field
                    as={TextField}
                    type="number"
                    name="tags"
                    variant="outlined"
                    margin="dense"
                    inputProps={{
                      min: "0",
                      max: "100",
                    }}
                    label={i18n.t("plans.plansModal.tags")}
                  />
                </div>
                <Field
                  as={TextField}
                  type="number"
                  name="duration"
                  variant="outlined"
                  margin="dense"
                  inputProps={{
                    min: "0",
                  }}
                  label={i18n.t("plans.plansModal.duration")}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {planId
                    ? `${i18n.t("plans.buttons.edit")}`
                    : `${i18n.t("plans.buttons.add")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PlanModal;
