import React, {
  createContext,
  useContext,
  useState,
  useReducer,
  useEffect,
} from "react";
import { useAuthUser } from "../Auth";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socket } from "../../services/socket-io";
const reducer = (state, action) => {
  if (action.type === "LOAD_WHATSAPPS") {
    const whatsApps = action.payload;

    return [...whatsApps];
  }

  if (action.type === "NEW_WHATSAPP") {
    state.push(action.payload);
    return state;
  }

  if (action.type === "UPDATE_WHATSAPPS") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex((s) => s.id === whatsApp.id);
    if (whatsAppIndex !== -1) {
      state[whatsAppIndex] = whatsApp;
    } else {
      state.push(whatsApp);
    }
    return state;
  }

  if (action.type === "UPDATE_SESSION") {
    const whatsApp = action.payload;
    const whatsAppIndex = state.findIndex((s) => s.id === whatsApp.id);

    if (whatsAppIndex !== -1) {
      state[whatsAppIndex].status = whatsApp.status;
      state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
      state[whatsAppIndex].qrcode = whatsApp.qrcode;
      state[whatsAppIndex].retries = whatsApp.retries;
      state[whatsAppIndex].tokenApi = whatsApp.tokenApi;
      return [...state];
    } else {
      return [...state];
    }
  }

  if (action.type === "DELETE_WHATSAPPS") {
    const whatsAppId = action.payload;

    const whatsAppIndex = state.findIndex((s) => s.id === whatsAppId);
    if (whatsAppIndex !== -1) {
      state.splice(whatsAppIndex, 1);
    }
    return state;
  }

  if (action.type === "RESET") {
    return [];
  }
};

export const WhatsAppsContext = createContext();

export function WhatsAppProvider({ children }) {
  const [whatsapps, dispatch] = useReducer(reducer, []);
  const [mounted, setMounted] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuthUser();

  const startConnection = async (id) => {
    try {
      await api.post(`/whatsappsession/${id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const newQRCode = async (id) => {
    try {
      await api.put(`/whatsappsession/${id}`);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (!user) return;
    const fetchSession = async () => {
      setIsLoading(true);
      try {
        if (mounted) return;
        const { data } = await api.get("/whatsapp/");
        if (!data) return;
        dispatch({ type: "LOAD_WHATSAPPS", payload: data });
        setMounted(true);
        return;
      } catch (err) {
        toastError(err);
        return;
      }
    };

    const onEvent = (event) => {
      setIsLoading(true);
      const { action, data } = event;
      if (action === "update") {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data });
      }

      if (action === "delete") {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data });
      }

      if (action === "create") {
        dispatch({
          type: "NEW_WHATSAPP",
          payload: data,
        });
      }
      setIsLoading(false);
    };

    fetchSession().finally(() => setIsLoading(false));

    socket.on(`whatsapp:${user.companyId}`, onEvent);

    return () => {
      socket.off(`whatsapp:${user.companyId}`, onEvent);
    };
  }, [user, mounted]);

  return (
    <WhatsAppsContext.Provider
      value={{
        whatsapps,
        isLoading,
        startConnection,
        newQRCode,
      }}
    >
      {children}
    </WhatsAppsContext.Provider>
  );
}

export const useWhatsApps = () => {
  return useContext(WhatsAppsContext);
};

export default WhatsAppsContext;
